<template lang="pug">
.flyover-handle(:class="{ 'is-open': isOpen }", @click="isOpen = !isOpen")
  .title
    i.fa
    | {{ title }}
  .content
    slot(name="default")
</template>
<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.flyover-handle {
  color: #e6e6e6;
  background-color: #3d3d3d;
  border-color: black;
  padding: 5px 12px;
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  .title {
    font-size: 85%;
    line-height: 1.3;
  }

  .content {
    display: none;
  }
  .fa:before {
    content: "\f078";
  }
  &.is-open {
    .fa:before {
      content: "\f077";
    }
    .content {
      display: block;
    }
  }

  .fa {
    margin-right: 5px;
  }

  .title {
    padding-left: 5px;
  }
}
</style>
