<template lang="pug">
.language-selector
  dropdown(v-if="availableLanguages && availableLanguages.length > 1" menu-right v-model="open")
    .toggle
      navbar-dropdown-button(:title="'activerecord.attributes.user.language' | i18n" :subtitle="currentLanguageName" icon="globe")
    template(slot="dropdown")
      li(v-for="language in availableLanguages")
        a.language-switch(@click="switchLanguage(language)") {{ language.name }}
</template>

<script lang="ts">
import Language from "../model/language";
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "../i18n";
import { Dropdown } from "uiv";
import { postForm } from "../lib/form";
import NavbarDropdownButton from "../components/navbar-dropdown-button.vue";
import { showOverlay } from "@/ui/overlay";

@Component({
  components: {
    Dropdown,
    NavbarDropdownButton,
  },
})
export default class LanguageSelector extends Vue {
  open = false;

  get currentLanguageName() {
    return window.zoinedContext.current_language_name;
  }

  get currentLanguageShortName() {
    return window.zoinedContext.current_language_short_name;
  }

  get availableLanguages() {
    return window.zoinedContext.available_languages;
  }

  toggleOpen() {
    console.log("toggle");
    this.open = !this.open;
  }

  switchLanguage(language: Language) {
    this.open = false;
    showOverlay(i18n.t("please_wait"));
    postForm(`/profile/switch_language/${language.code}`);
  }
}
</script>
<style lang="scss">
.language-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }
}
</style>
