import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { makeApiInstance } from "@/api/instance";
import {
  MutationStatus,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useMutationState,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import { MaybeRefDeep } from "@tanstack/vue-query/build/legacy/types";
import { computed, ref, Ref } from "vue";

type UseCreateAiMessageArgs = {
  session_id: string;
  data: {
    message: string;
    context?: Record<string, any>;
    use?: Record<string, any>;
  };
};

type UseCreateAiMessageResult = AiMessage & {
  answer: AiMessage;
};

const api = makeApiInstance({ baseURL: "/api/v1" });

const useAiSessions = () => {
  const perPage = 20;

  return useInfiniteQuery<AiSession[]>({
    queryKey: ["aiSessions"],
    queryFn: ({ pageParam = 1 }) =>
      api.get("/ai_sessions", { params: { page: pageParam, per_page: perPage } }).then((res) => res.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) {
        return 1;
      }
      if (lastPage.length < perPage) {
        return undefined;
      }
      return pages.length + 1;
    },
  });
};

const useCreateAiSession = (options: UseMutationOptions<AiSession> = {}) => {
  return useMutation<AiSession>({
    ...options,
    mutationKey: ["addAiSession"],
    mutationFn: () => api.post("/ai_sessions").then((res) => res.data),
  });
};

const useCreateAiSessionState = (status: MaybeRefDeep<MutationStatus> = "pending") => {
  return useMutationState({
    filters: {
      mutationKey: ["addAiSession"],
      status,
    },
  });
};

const useAiMessages = (session_id: Ref<string>) => {
  const enabled = computed(() => !!session_id.value);
  return useQuery<AiMessage[]>({
    queryKey: ["aiMessages", session_id],
    queryFn: () => api.get("/ai_messages", { params: { session_id: session_id.value } }).then((res) => res.data),
    enabled,
  });
};

const useCreateAiMessage = (
  options: UseMutationOptions<UseCreateAiMessageResult, Error, UseCreateAiMessageArgs> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<UseCreateAiMessageResult, Error, UseCreateAiMessageArgs>({
    ...options,
    mutationKey: ["addAiMessage"],
    mutationFn: (args: UseCreateAiMessageArgs) => api.post("/ai_messages", args).then((res) => res.data),
    onSuccess: (_result, args) => {
      queryClient.invalidateQueries({ queryKey: ["aiMessages", args.session_id] });
    },
  });
};

const useCreateAiMessageState = (status: MaybeRefDeep<MutationStatus> = "pending") => {
  return useMutationState({
    filters: {
      mutationKey: ["addAiMessage"],
      status,
    },
    select: (mutation) => mutation.state.variables,
  });
};

export {
  useAiSessions,
  useCreateAiSession,
  useCreateAiSessionState,
  useAiMessages,
  useCreateAiMessage,
  useCreateAiMessageState,
  UseCreateAiMessageArgs,
  UseCreateAiMessageResult,
};
