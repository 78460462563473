<template>
  <div id="ai-assistant" :class="{ open }">
    <ai-assistant-panel />
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed } from "vue";
import AiAssistantPanel from "@/ai-assistant/ai-assistant-panel.vue";
import { useRouter } from "@/composables/router";
import Mutations from "@/store/mutations";

const store = useStore();

const router = useRouter();

const open = computed(() => store.state.aiAssistant.open);

router.afterEach((to) => {
  if (to.name !== "zed_workspace") {
    store.commit(Mutations.closeAiAssistant);
    store.commit(Mutations.setAiSessionId, null);
  }
});
</script>

<style lang="scss" scoped>
#ai-assistant {
  display: none;

  @media screen {
    display: block;
    position: fixed;
    bottom: var(--sidenav-iconbar-width);
    width: 100%;
    height: auto;
    z-index: var(--z-index-ai-assistant);
  }
  @media screen and (min-width: 992px) {
    display: block;
    position: fixed;
    top: var(--header-height);
    bottom: unset;
    right: 0;
    width: var(--ai-assistant-width);
    height: calc(100vh - var(--header-height));
    padding: 30px 15px 15px 15px;
    overflow: hidden;
    transform: translateX(var(--ai-assistant-width));
    transition: all 0.3s;

    &.open {
      transform: translateX(0);
    }
  }

  @media print {
    display: none;
  }
}
</style>
