<template lang="pug">
.dashboard-body
  printableFilters(:filter-configuration="flatFilterConfiguration")

  .flex-row.justify-content-center-except-xs.mt-sm.mb-lg
    legends(:selection="flatFilterConfiguration.selection", :comparisons="flatFilterConfiguration.comparisons")

  #snippets-container(v-if="snippets && snippets.length > 0")
    zoined-report(:components="snippets", :filter-configuration="flatFilterConfiguration", :controls-enabled="false")

  #charts-container(v-if="charts && charts.length > 0")
    zoined-report(:components="charts", :filter-configuration="flatFilterConfiguration" :controls-enabled="false")
</template>

<script lang="ts">
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import {
  configToActive,
  convertGroupingToBusinessCalendar,
  DASHBOARD_FILTERS,
  extendComparisonSeries,
  flatFilterConfiguration,
} from "../lib/filter-util";
import _ from "lodash";
import $ from "jquery";
import i18n from "../i18n";
import Legends from "../components/legends.vue";
import PrintableFilters from "../filters/printable-filters.vue";
import ReportContext from "../model/report-context";
import zoinedReport from "../analytics/zoined-report.vue";
import { availableComponents } from "../custom-dashboard-editor/component-types";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import Mutations from "../store/mutations";
import { makeApiInstance } from "../api/instance";

@Component({
  components: {
    Legends,
    PrintableFilters,
    zoinedReport,
  },
})
export default class DashboardView extends Vue {
  snippets: any[] = [];
  charts: any[] = [];

  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get filterConfiguration(): FilterConfiguration {
    return this.reportContext.filter_configuration;
  }

  get zoinedContext() {
    return window.zoinedContext;
  }

  get useBusinessCalendar() {
    return this.zoinedContext.useBusinessCalendar;
  }

  get filter() {
    return configToActive(this.flatFilterConfiguration);
  }

  get flatFilterConfiguration(): any {
    return _.omit(flatFilterConfiguration(this.filterConfiguration), DASHBOARD_FILTERS);
  }

  get company() {
    return this.zoinedContext.company;
  }

  isExtreme(id) {
    return id && !id.includes("_notifications");
  }

  setAsDefault() {
    const url = `/api/v1/reports/default_dashboard`;
    return makeApiInstance()
      .post(url)
      .then(() => {
        this.$store.commit(Mutations.setDefaultDashboardId, null);
      });
  }

  created() {
    this.updateComponents();
    EventBus.subscribe(Events.setAsDefault, this.setAsDefault);
  }

  destroyed() {
    EventBus.unsubscribe(Events.setAsDefault, this.setAsDefault);
  }

  @Watch("filterConfiguration")
  updateComponents() {
    const { snippets, charts } = configToActive(this.filterConfiguration.widgets);
    this.snippets = snippets?.map((s) => this.getSnippet(s));
    this.charts = charts?.map((s) => this.getChart(s));
  }

  private getSnippet(name: any) {
    return {
      ...availableComponents.dashboard_snippet,
      custom_title: this.customTitle(name),
      help_text: i18n.t(`snippet.${name}.desc`, {
        defaultValue: "",
        currency: this.zoinedContext.currency.symbol,
      }),
      name: this.isExtreme(name) ? "dashboard_snippet" : name,
      type: this.isExtreme(name) ? "dashboard_snippet" : "snippet",
      filter: {
        ...this.filter,
        metrics: [name],
      },
      span: this.filterConfiguration.raw_filters.span || 6,
    };
  }

  private getChart(name: any) {
    let f = extendComparisonSeries(this.filter.comparisons, this.flatFilterConfiguration.selection);
    if (this.useBusinessCalendar) {
      f.grouping = convertGroupingToBusinessCalendar(f.grouping);
    }
    const filter = $.extend({}, this.filter, f, { metrics: [name] });
    return {
      ...availableComponents.metric_chart,
      custom_title: this.customTitle(name),
      help_text: i18n.t(`chart.${name}.desc`, {
        defaultValue: "",
        currency: this.zoinedContext.currency.symbol,
      }),
      filter,
    };
  }

  customTitle(name) {
    const title = i18n.t(`chart.${name}.title`, {
      defaultValue: "",
    });

    return title.length > 0 ? title : null;
  }

  @Watch("filterConfiguration")
  storeConfig() {
    const config = this.filterConfiguration;

    fetch("/dashboard/store_config", {
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ config }),
    });
  }
}
</script>

<style lang="scss" scoped>
.dashboard-body::v-deep {
  .report-component.metric_chart {
    height: 300px;
  }
}
</style>
