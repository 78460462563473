<!-- Non modal pill select component (all items are rendered and enabled by toggling) -->
<template lang="pug">
.flex-row.align-items-center.flex-wrap.gap-sm
  pill-button(:enabled="item.enabled", :removable="false", @toggle="toggleItem(item)" v-for="(item, index) in items" :key="index")
    | {{ item.label }}
</template>
<script>
import PillButton from "@/components/pill-button.vue";

export default {
  components: { PillButton },
  props: {
    items: {
      type: Array,
      required: true,
    },
    radio: Boolean,
  },
  methods: {
    toggleItem(item) {
      const items = this.items.map((each) =>
        this.radio
          ? { ...each, enabled: each === item }
          : {
              ...each,
              enabled: each === item ? !each.enabled : each.enabled,
            }
      );
      this.$emit("change", items);
    },
  },
};
</script>
