<template lang="pug">
spinner(v-if="loading")
div(v-else)
	h4.mt-0 {{"profile.my_top_reports" | i18n}}

	.panel
		.panel-body
			.profile-reports
				ol(v-if="hasReports")
					li(v-for="report in reports")
						a(v-bind:href="'analytics/report/'+report.name") {{`report.${report.name}.title` | i18n}}
				p(v-else) {{"no_records" | i18n}}
</template>

<script>
import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
  },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted: function() {
    this.loading = !!this.reports.length;
  },
  computed: {
    hasReports: function() {
      return !!this.reports.length;
    },
    linkTo: function(name) {
      return `/analytics/reports/${name}`;
    },
  },
};
</script>
