<template>
  <div class="ai-message pt-md" ref="el">
    <div v-if="isUserMessage" class="align-self-flex-end bg-surface-neutral-2 rounded p-md ml-xxl">
      {{ aiMessage.data.message }}
    </div>
    <ai-chat-card v-else>
      {{ aiMessage.data.message }}
      <ai-chart-card v-if="chartState" class="mt-lg" :chart="chartState" @click="scrollToMessage" />
    </ai-chat-card>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import AiMessage from "@/ai-assistant/ai-message";
import aiChatCard from "@/ai-assistant/ai-chat-card.vue";
import aiChartCard from "@/ai-assistant/ai-chart-card.vue";
import { buildChartState, scrollMessageComponentViewToTop } from "@/ai-assistant/ai-utils";
import { useIsMobile } from "@/composables/is-mobile";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";

const el = ref<HTMLElement | null>(null);

const props = defineProps<{
  aiMessage: AiMessage;
}>();

const store = useStore();

const isMobile = useIsMobile();

const isUserMessage = computed(() => props.aiMessage.sender !== "ai");

const chartState = computed(() => {
  if (props.aiMessage.data.chart && props.aiMessage.data.filterConfiguration) {
    return buildChartState(props.aiMessage.data.chart, props.aiMessage.data.filterConfiguration);
  }
});

const scrollToMessage = () => {
  scrollMessageComponentViewToTop(props.aiMessage.id);

  if (isMobile.value) {
    setTimeout(() => {
      if (open) {
        store.commit(Mutations.closeAiAssistant);
      }
    }, 500);
  }
};

onMounted(() => {
  if (isUserMessage.value) {
    setTimeout(() => {
      el.value.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  }
});
</script>
