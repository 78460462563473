<template>
  <div class="ai-assistant-workspace-page report-body">
    <save-report-dialog ref="saveReportDialog" :config="saveReportConfig"></save-report-dialog>
    <div
      v-if="aiMessages.length == 0"
      class="placeholder flex-row align-items-center justify-content-center"
      :class="{ animate: animatePlaceholder }"
    >
      <icon icon="fluent:sparkle-20-filled" class="fg-text-secondary" width="200" />
    </div>
    <ai-assistant-component-view
      ref="componentViews"
      :id="'ai-assistant-component-view-' + messageComponent.id"
      :key="messageComponent.id"
      :component="messageComponent.component"
      @component-updated="updateComponent(messageComponent.id, $event)"
      @loaded="onComponentReady"
      v-for="messageComponent in messageComponents"
    ></ai-assistant-component-view>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composables/store";
import { computed, ref, watch, watchEffect } from "vue";
import AiAssistantComponentView from "@/ai-assistant/ai-assistant-component-view.vue";
import Mutations from "@/store/mutations";
import { useEvent } from "@/composables/events";
import Events from "@/events/events";
import SaveReportDialog from "@/save-report/save-report-dialog.vue";
import ReportConfig from "@/model/report-config";
import { useAiMessages, useCreateAiMessageState, useCreateAiSessionState } from "@/ai-assistant/ai-assistant-api";
import { Icon } from "@iconify/vue2";
import { getComponent, getComponentType, scrollMessageComponentViewToTop } from "@/ai-assistant/ai-utils";
import _ from "lodash";

export default {
  beforeRouteLeave(to, from, next) {
    this.handleRouteLeave(next);
  },
  components: {
    AiAssistantComponentView,
    SaveReportDialog,
    Icon,
  },
  setup() {
    const componentViews = ref<typeof AiAssistantComponentView[]>([]);

    const saveReportDialog = ref<typeof SaveReportDialog>(null);

    const ready = ref(false);

    const store = useStore();

    useEvent(Events.saveReport, () => {
      saveReportDialog.value?.show();
    });

    const sessionId = computed(() => store.state.aiAssistant.session_id);

    const { isFetching, data } = useAiMessages(sessionId);

    const pendingSessions = useCreateAiSessionState();

    const pendingMessages = useCreateAiMessageState();

    const aiMessages = computed(
      () =>
        data.value?.filter((message) => {
          return message.sender == "ai" && message.data.chart && message.data.filterConfiguration;
        }) || []
    );

    const messageComponents = computed(() => {
      return aiMessages.value.map((message) => ({ id: message.id, component: componentForMessage(message) }));
    });

    const reportContext = computed(() => {
      return {
        my_report: { editable: true },
        has_changes: messageComponents.value.length > 0,
      };
    });

    const saveReportConfig = computed<ReportConfig>(() => {
      return {
        components: messageComponents.value.map((messageComponent) => messageComponent.component),
      } as ReportConfig;
    });

    const animatePlaceholder = computed(
      () => isFetching.value || pendingSessions.value.length > 0 || pendingMessages.value.length > 0
    );

    const componentForMessage = (message) => {
      let component = store.state.aiAssistant.components[message.id];
      if (!component) {
        const { chart, filterConfiguration } = message.data;
        const componentType = getComponentType(chart);
        component = getComponent(componentType, filterConfiguration);
        store.commit(Mutations.setAiComponent, { id: message.id, component });
      }
      return component;
    };

    const updateComponent = (id, component) => {
      store.commit(Mutations.setAiComponent, { id, component });
    };

    // Check if all components are ready
    const onComponentReady = () => {
      if (!ready.value) {
        ready.value = componentViews.value.every((view) => view.isLoaded);
      }
    };

    // Reset ready state when new session is created or new AI messages are received
    watch([sessionId, aiMessages], () => {
      ready.value = false;
    });

    // Scroll into last component when all components are ready
    watchEffect(() => {
      if (ready.value) {
        const lastMessage = _.last(aiMessages.value);
        if (lastMessage) {
          setTimeout(() => {
            scrollMessageComponentViewToTop(lastMessage.id);
          }, 300);
        }
      }
    });

    watch(
      reportContext,
      (ctx) => {
        store.commit(Mutations.fetchReportContextSuccess, ctx);
      },
      { immediate: true }
    );

    const handleRouteLeave = (next) => {
      store.commit(Mutations.clearReportContext);
      next();
    };

    return {
      animatePlaceholder,
      messageComponents,
      handleRouteLeave,
      saveReportDialog,
      saveReportConfig,
      isFetching,
      aiMessages,
      componentViews,
      updateComponent,
      onComponentReady,
    };
  },
};
</script>

<style scoped lang="scss">
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.placeholder {
  @media (min-width: 992px) {
    margin-top: calc(50vh - var(--header-height) - 200px);
    &.animate {
      animation: pulse 1s infinite;
    }
  }
}
</style>
