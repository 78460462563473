<template lang="pug">
.budget-planning
  spinner(v-if="uploading")

  .row.uploaded-budgets
    .col-md-12

      h4 {{ 'company_admin.budget.uploaded_budgets' | i18n }}

      .spinner-base
        spinner(v-if="loading")
        .notifications(v-if="notifications")
          .alert(:class="{'alert-success': notification.status=='ok', 'alert-danger': notification.status=='error'}", v-for="notification in notifications")
            .pull-right
              button.close(type="button", @click="deleteNotification(notification)") &times;
            | <b>{{notification.created_at | date}}</b>: {{notification.message}}

        div(v-if="!loading")
          p(v-if="uploadedMetrics") {{ 'company_admin.budget.uploaded_budgets_desc' | i18n }}
          p(v-if="!uploadedMetrics") {{ 'company_admin.budget.no_uploaded_budgets_desc' | i18n }}

          table.table(v-if="uploadedMetrics")
            thead
              tr
                th {{ 'filter.config.year' | i18n }}
                th {{ 'company_admin.budget.type' | i18n }}
                th {{ 'filter.config.metrics' | i18n }}
                th {{ 'filter.config.grouping' | i18n }}
            tbody
              tr(v-for="row in uploadedMetrics")
                td {{ row.year }}
                td {{ row.type }}
                td {{ row.metric }}
                td {{ row.dimensions.join(", ") }}


  .row
    .col-md-12
      h4 {{ 'company_admin.budget.download_budget' | i18n }}

      p {{ 'company_admin.budget.download_budget_desc' | i18n }}

      .alert.alert-danger(v-if="errors.download") {{ errors.download }}

      form
        .row
          .form-group.col-md-4
            label {{ 'attributes.start_date' | i18n }}
            date-picker(v-model="download.start_date")
          .form-group.col-md-4
            label {{ 'attributes.end_date' | i18n }}
            date-picker(v-model="download.end_date")
          .form-group.col-md-4
            label {{ 'company_admin.budget.type' | i18n }}
            select.form-control(v-model="download.type")
              option(:value="t" v-for="t in availableTypes") {{ 'filter.config.' + t | i18n }}
          .form-group.col-md-4
            label {{ 'company_admin.budget.plan_type' | i18n }}
            select.form-control(v-model="download.label")
              option(:value="label" v-for="label in availableLabels") {{ 'filters.budgets.' + label | i18n }}
          .form-group.col-md-4
            label {{ 'filter.config.metrics' | i18n }}
            div(v-if="config")
              metrics-selector(:only-ids="config.valid.metrics" :value="download.metrics" @input="updateMetrics")
          .form-group.col-md-4
            label {{ 'filter.config.grouping' | i18n }}
            div(v-if="config")
              groupings-selector(:only-ids="config.valid.dimensions" :value="download.dimensions" @input="updateDimensions")
        .row
          .form-group.col-md-12
            label {{ 'filters.compare_to' | i18n }}
            pill-select(:items="comparisonItems" @change="updateComparison")
        .row
          .form-group.col-md-12
            label {{ 'filters.title' | i18n }}
            .flex-row.align-items-center.gap-lg.column-gap-xxl.flex-wrap(v-if="showFilters")
              filter-selector(:config="filters[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in filterItems" :key="filter.id")
            div(v-else)
              a(@click="showFilters = true") {{ 'filters.add_filters' | i18n }}
          .col-md-12
            button.btn.btn-primary(type="button" @click="downloadTemplate()") {{'company_admin.budget.download_budget' |i18n}}

  .row
    .col-md-12
      h4 {{ 'company_admin.budget.update_budgets' | i18n }}

      p {{ 'company_admin.budget.update_budgets_desc' | i18n }}

      .alert.alert-success(v-if="uploadSuccess") {{ 'company_admin.budget.budget_saved_desc' | i18n }}

      .alert.alert-danger(v-if="errors.upload") {{ errors.upload }}

      .file-drop
        | {{ 'company_admin.budget.upload_budget' | i18n }}
        input(type="file", @change="onFileChange", ref="fileInput")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Spinner from "../components/spinner.vue";
import metricsSelector from "../components/metrics-selector.vue";
import groupingsSelector from "../components/groupings-selector.vue";
import { Ref } from "vue-property-decorator";
import _ from "lodash";
import adminCompaniesApi from "../api/admin-companies-api";
import adminNotificationsApi from "../api/admin-notifications-api";
import { metricLabel } from "../lib/i18n-util";
import I18n from "../i18n";
import toaster from "toastr";
import moment from "moment";
import { downloadFile } from "@/lib/download";
import datePicker from "@/components/date-picker.vue";
import MenuItem from "@/interfaces/menu-item";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import FilterSelector from "@/components/filter-selector.vue";
import { configToActive } from "@/lib/filter-util";
import { hideOverlay, showOverlay } from "@/ui/overlay";
import PillSelect from "@/components/pill-select.vue";

@Component({
  components: {
    Spinner,
    metricsSelector,
    groupingsSelector,
    datePicker,
    FilterSelector,
    PillSelect,
  },
})
export default class BudgetPlanning extends Vue {
  @Ref("fileInput")
  fileInput: HTMLInputElement;

  loading = false;

  config = null;

  uploadedMetrics = null;

  notifications = null;

  uploading = false;

  uploadSuccess = false;

  showFilters = false;

  download = {
    start_date: moment()
      .startOf("year")
      .toDate(),
    end_date: moment()
      .endOf("year")
      .toDate(),
    type: "month",
    metrics: [],
    dimensions: [],
    label: "budget",
  };

  errors = {
    upload: "",
    download: "",
  };

  notificationInterval = null;

  filters = {};

  comparisonItems = [
    { value: "prev_year_corresponding", label: I18n.t("filters.benchmark.prev_year_corresponding"), enabled: true },
    {
      value: "2_years_back_corresponding",
      label: I18n.t("filters.benchmark.2_years_back_corresponding"),
      enabled: true,
    },
  ];

  readonly availableYears = [moment().year() + 2, moment().year() + 1, moment().year(), moment().year() - 1];
  readonly availableTypes = ["month", "week", "day"];
  readonly availableLabels = ["budget", "forecast", "target"];

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get filterItems(): MenuItem[] {
    return this.$store.getters.getParameters("filters");
  }

  created() {
    this.fetchBudgetConfiguration();
    this.fetchNotifications();
    this.notificationInterval = setInterval(() => {
      this.fetchNotifications();
    }, 60000);
  }

  destroyed() {
    clearInterval(this.notificationInterval);
  }

  fetchBudgetConfiguration() {
    this.loading = true;
    adminCompaniesApi
      .getBudgetConfiguration({ id: this.companyId, yearly: true })
      .then((config) => {
        this.config = config;
        this.uploadedMetrics = _.flatten(
          _.map(config.budgeted.budget, (budgets, m) => {
            return _.map(budgets, (budget) => {
              return {
                metric: metricLabel(m),
                year: budget.year,
                type: budget.type == "D" ? I18n.t("filter.config.day") : I18n.t("filter.config.month"),
                dimensions: _.sortBy(budget.dimensions.map((d) => I18n.t(`filter.config.${d}`))),
              };
            });
          })
        );
      })
      .finally(() => (this.loading = false));
  }

  fetchNotifications() {
    adminNotificationsApi.get({ company_id: this.companyId, type: "budget" }).then((results) => {
      this.notifications = results;
    });
  }

  onFileChange() {
    const files = this.fileInput.files;
    if (files.length > 0) {
      this.uploadFile(files[0]);
    }
  }

  uploadFile(file) {
    this.uploading = true;
    this.uploadSuccess = false;
    const formData = new FormData();
    formData.append("file", file);
    fetch(`/api/admin/companies/${this.companyId}/budgets`, {
      method: "POST",
      body: formData,
    })
      .then((result) => {
        if (!result.ok) {
          return result.json().then((data) => {
            throw data;
          });
        }
        this.uploadSuccess = true;
        this.errors.upload = "";
        toaster.success(I18n.t("company_admin.budget.budget_saved"));
        this.fetchBudgetConfiguration();
      })
      .catch((err) => {
        toaster.error(I18n.t("company_admin.budget.error_storing"), { timeOut: 0 });
        this.errors.upload = err.error || I18n.t("company_admin.budget.unknown_error");
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  downloadTemplate() {
    const errors = [];
    if (_.isEmpty(this.download.metrics)) {
      errors.push(I18n.t("company_admin.budget.errors.select_metric"));
    }
    if (errors.length > 0) {
      this.errors.download = errors.join(", ");
      return;
    } else {
      this.errors.download = "";
      const params = new URLSearchParams({
        start_date: moment(this.download.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.download.end_date).format("YYYY-MM-DD"),
        type: this.download.type,
        label: this.download.label,
      });
      const comparisons = this.comparisonItems.filter((c) => c.enabled).map((c) => c.value);
      if (comparisons.length > 0) {
        comparisons.forEach((c) => params.append("comparisons[]", c));
      } else {
        params.append("comparisons[]", "");
      }
      this.download.metrics
        .filter((m) => m.enabled)
        .map((m) => m.id)
        .forEach((m) => params.append("metrics[]", m));
      this.download.dimensions
        .filter((m) => m.enabled)
        .map((m) => m.id)
        .forEach((m) => params.append("dimensions[]", m));
      const filters: Record<string, string[]> = configToActive(this.filters);
      Object.keys(filters).map((key) => {
        const values = filters[key];
        values.forEach((value) => {
          params.append(`filters[${key}][]`, value);
        });
      });

      const url = `/api/admin/companies/${this.companyId}/budgets?${params.toString()}`;

      showOverlay(I18n.t("please_wait"));
      return downloadFile(url)
        .then(() => {
          toaster.success(I18n.t("company_admin.budget.budget_downloaded"));
        })
        .catch(() => {
          toaster.error(I18n.t("company_admin.budget.errors.failed_to_download"));
        })
        .finally(() => {
          hideOverlay();
        });
    }
  }

  deleteNotification(notification) {
    adminNotificationsApi.delete({ company_id: this.companyId, id: notification.id }).then(() => {
      this.notifications = _.filter(this.notifications, (n) => n.id !== notification.id);
    });
  }

  updateDimensions(dimensions) {
    this.download.dimensions = dimensions;
  }

  updateMetrics(metrics) {
    this.download.metrics = metrics;
  }

  updateFilter(filter: string, config: FilterConfigurationItemMap) {
    this.filters = {
      ...this.filters,
      [filter]: config,
    };
  }

  updateComparison(items) {
    this.comparisonItems = items;
  }
}
</script>
