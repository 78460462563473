import { availableComponents } from "@/custom-dashboard-editor/component-types";
import { flatFilterConfiguration } from "@/lib/filter-util";
import FilterConfiguration from "@/model/filter-configuration";
import { ChartState } from "./ai-assistant-lottery-state";

export const getComponentType = (chart: string) => {
  switch (chart) {
    case "pie":
      return "pie";
    case "line":
      return "line";
    case "bar":
    case "column":
      return "dual_axis";
    case "table":
      return "cross_table";
    case "cross-table":
      return "cross_tab_custom";
  }
};

export const getComponentIconName = (chart: string) => {
  switch (chart) {
    case "pie":
      return "component-pie-chart";
    case "line":
      return "component-line-graph";
    case "table":
      return "component-table";
    case "cross-table":
      return "component-crosstab";
    default:
      return "component-bar-chart";
  }
};

export const getComponent = (componentType, filterConfiguration) => {
  const baseConfiguration = availableComponents[componentType];
  filterConfiguration = flatFilterConfiguration(filterConfiguration);

  return {
    ...baseConfiguration,
    filterConfiguration,
  };
};

export const buildChartState = (chart: string, filterConfiguration: FilterConfiguration): ChartState => {
  const selection = filterConfiguration.time.selection.type;
  const comparison = filterConfiguration.time.comparisons?.[0]?.type;
  const metric = Object.keys(filterConfiguration.widgets.metrics)[0];
  const grouping = Object.keys(filterConfiguration.widgets.grouping)[0];

  return { chart, selection, comparison, metric, grouping };
};

export const scrollMessageComponentViewToTop = (id: number) => {
  const el = document.getElementById(`ai-assistant-component-view-${id}`);
  if (!el) {
    return;
  }
  const windowWidth = window.innerWidth;
  const mobileBreakpoint = 992;
  if (windowWidth < mobileBreakpoint) {
    const menubarMobile = document.getElementById("menubar-mobile");
    const offsetTop = menubarMobile?.getBoundingClientRect()?.bottom || 0;
    const top = el.getBoundingClientRect().top + window.scrollY - offsetTop;
    window.scrollTo({ top, behavior: "smooth" });
  } else {
    const zoinedNav = document.getElementById("zoined-nav");
    const offsetTop = zoinedNav?.getBoundingClientRect()?.bottom || 0;
    const top = el.getBoundingClientRect().top + window.scrollY - offsetTop;
    window.scrollTo({ top, behavior: "smooth" });
  }
};
