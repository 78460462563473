<template>
  <div ref="scrollContainer" class="flex-column gap-xxl pt-xl overflow-y-scroll scrollbar-hidden" v-if="aiSessions">
    <div class="flex-column gap-md" v-for="(sessions, key) in groupedSessions" :key="key">
      <div>{{ key }}</div>
      <div
        class="ai-session p-sm font-weight-sb cursor-pointer"
        v-for="session in sessions"
        :key="session.session_id"
        :class="{ active: session.session_id == currentSessionId }"
        @click="selectSession(session)"
      >
        {{ session.description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAiSessions } from "@/ai-assistant/ai-assistant-api";
import { useI18n } from "@/composables/i18n";
import { useStore } from "@/composables/store";
import { useInfiniteScroll } from "@vueuse/core";
import _ from "lodash";
import moment from "moment";
import { computed, ref } from "vue";

const emit = defineEmits(["select-session"]);

const { t } = useI18n();

const scrollContainer = ref<HTMLElement | null>(null);

const store = useStore();

const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useAiSessions();

const aiSessions = computed(() => _.flatten(data.value?.pages));

const currentSessionId = computed(() => store.state.aiAssistant.session_id);

useInfiniteScroll(scrollContainer, () => {
  if (hasNextPage.value && !isFetchingNextPage.value) {
    fetchNextPage();
  }
});

const groupedSessions = computed(() => {
  return _.groupBy(aiSessions.value, (session) => {
    const time = moment(session.created_at);
    if (time.isSame(moment(), "day")) {
      return t("time_period.today");
    } else if (time.year() == moment().year() && time.week() == moment().week()) {
      return t("time_period.this_week");
    } else if (time.year() == moment().year()) {
      return time.format("MMMM");
    } else {
      return time.format("YYYY MMMM");
    }
  });
});

const selectSession = (session) => {
  emit("select-session", session);
};
</script>

<style scoped>
.ai-session {
  &:hover,
  &.active {
    color: var(--color-text-emphasis-variant);
  }
}
</style>
