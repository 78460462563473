<template>
  <transition name="ai-assistant-button">
    <button v-if="!isOpen" id="ai-assintant-button" class="btn" @click="toggle"></button>
  </transition>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { computed } from "vue";

const store = useStore();

const isOpen = computed(() => store.state.aiAssistant.open);

const toggle = () => {
  store.commit(Mutations.toggleAiAssistant);
};
</script>

<style lang="scss" scoped>
button.btn#ai-assintant-button {
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }

  background-image: url("~@assets/images/zed-front.png");
  background-size: cover;
  outline: none !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: none;
  background-color: var(--color-surface-neutral-1);
  color: var(--color-text-emphasis);
  box-shadow: var(--elevation-md);
  font-size: 20px;
  line-height: 1;

  &:hover {
    box-shadow: 0px 0px 4px 2px var(--color-text-emphasis);
  }

  &:active,
  &.open {
    box-shadow: none;
    background-color: var(--color-interactive-bg-emphasis-2);
    color: var(--color-text-primary-inverse);
  }
}
</style>

<style lang="scss">
.ai-assistant-button-enter-active {
  animation: fade-and-widen 1s;
}
.ai-assistant-button-leave-active {
  animation: fade-and-widen 0.2s reverse;
}
@keyframes fade-and-widen {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    width: 46px;
    opacity: 1;
  }
}
</style>
