import Component from "@/model/component";
import RootState from "@/store/root-state";
import { Module } from "vuex";

export type AiAssistantState = {
  open: boolean;
  session_id: string;
  components: Record<number, Component>;
};

const aiAssistant: Module<AiAssistantState, RootState> = {
  namespaced: true,
  state: () => ({
    open: false,
    session_id: null,
    messages: [],
    components: {},
  }),
  mutations: {
    toggle(state) {
      state.open = !state.open;
    },
    close(state) {
      state.open = false;
    },
    setSessionId(state, session_id: string) {
      state.session_id = session_id;
    },
    setComponent(state, { id, component }) {
      state.components = {
        ...state.components,
        [id]: component,
      };
    },
  },
};

export default aiAssistant;
